@import "../../assets/styles/variables.scss";
@import "../../assets/styles/global.scss";



.container {
  display: flex;
  flex-direction: column;

  &_mainSection {
    color: $golden;
    background-image: url("../../assets/images/backgrounds/welcome_section_background.jpg");
    background-position: center;
    background-repeat: repeat;
    background-attachment: fixed;
    background-repeat: repeat;
    padding: 20px 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    text-align: center;
    padding: 20px 25%;

	
    min-height: 100vh;
    > h1 {
      text-align: center;
      color: $spaceBlue;
    }
    > p {
      color: $golden;
      font-size: 24px;
    }
  }
  &_infoSection {
    text-align: center;
    padding: 20px 50px;
    display: flex;
    flex-direction: column;
    background-image: url("../../assets/images/backgrounds/welcome_section_background.jpg");
	padding: 20px calc(25% - 50px); /* Aggiungi spazio libero del 25% a sinistra e a destra */
    background-position: center;
    background-repeat: repeat;
    background-attachment: fixed;
    &_links {
      display: none;
    }
    &:nth-child(6) {
      background-image: url("../../assets/images/backgrounds/welcome_section_bottom_background.jpg");
      height: 50vh;
      gap: 3vw;
      .container_infoSection_links {
        display: flex;
        justify-content: center;
        gap: 20px;
        > a {
          > img {
            width: 100px;
            height: 100px;
            @media (max-width: $small-screen) {
              width: 60px;
              height: 60px;
            }
            @media (max-width: $mobile-screen) {
              width: 48px;
              height: 48px;
            }
          }
          :hover {
            transition: all 0.2s ease-in-out;
            transform: rotate(360deg);
          }
          :not(:hover) {
            transition: all 0.2s ease-in-out;
            transform: rotate(0deg);
          }
        }
      }
    }
    &_info {
      display: flex;
      flex-direction: column;
      gap: 20px;
      > h2 {
        color: $spaceBlue;
      }
      > p {
        color: $golden;
        font-size: 24px;
      }
    }
  }
}

@media (max-width: $small-screen) {
  .container {
    &_mainSection {
      > p {
        font-size: 15px;
      }
    }
    &_infoSection {
      &_info {
        > p {
          font-size: 15px;
        }
      }
    }
  }
}
@media (max-width: $mobile-screen) {
  .container {
    &_mainSection {
      > h1 {
        font-size: 6vw;
      }
      > p {
        font-size: 3vw;
      }
    }
    &_infoSection {
      &_info {
        > h2 {
          font-size: 6vw;
        }
        > p {
          font-size: 3vw;
        }
      }
    }
  }
}
.introVideo {
  width: 50%; // Imposta la larghezza del video al 100% del suo contenitore
  max-width: 50vw; // Imposta la larghezza massima del video al 100% della larghezza del viewport
  height: auto; // Imposta l'altezza del video in modo proporzionale alla larghezza
  position: relative; // Imposta il posizionamento relativo per consentire il posizionamento del video
  z-index: 0; // Assicura che il video sia posizionato dietro ad altri elementi
}

