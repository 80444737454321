@import "../../assets/styles/variables.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  color: $golden; // Assicurati che questo colore sia definito nelle tue variabili
  font-size: 24px;
  font-weight: 600;

  > h2 {
    color: $spaceBlue; // Utilizza lo stesso colore per gli h2 come in MyNftsPage
  }
  > h3 {
    color: $spaceBlue; // Utilizza lo stesso colore per gli h2 come in MyNftsPage
  }
  > p {
    font-size: 24px;
    font-weight: 600;
  }

  .nft-list, .table-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    table {
      width: 100%;
      border-collapse: collapse;

      th, td {
        border: 1px solid $spaceBlue;
        padding: 10px;
        text-align: left;
        color: $golden; // Usa la variabile di colore appropriata
      }

      img {
        width: auto; // Assicurati che le dimensioni delle immagini siano responsive e si adattino bene
        height: auto;
      }
    }
  }

  // Stili aggiornati per la griglia dei booster
  .boosterGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr)); // Adatta per responsive
    gap: 20px;
    width: 75%; // Imposta la griglia al 75% della larghezza della pagina
  }

  .boosterItem {
    text-align: center;
  }

  .boosterImage {
    width: 100%; // Imposta le immagini al 100% della larghezza del contenitore, ma con max-width per limitarne la dimensione
    max-width: 25%; // Massimo 25% della larghezza della griglia
    height: auto; // Mantiene il rapporto d'aspetto
  }
}

// Media query per dispositivi mobili
@media (max-width: 768px) {
  .container {
    font-size: 4.5vw;
    padding: 5%;

    > p {
      font-size: 4.5vw;
    }
    > h2, > h3 {
      color: $spaceBlue;
    }

    .nft-list, .table-container {
      gap: 2%;

      table th, table td {
        padding: 2%;
      }

      img {
        max-width: 20%; // Limita la larghezza massima delle immagini in percentuale
        height: auto;
      }
    }
  }
}
