@import "../../assets/styles/variables.scss";

.container {
  width: 100%;
  color: $golden;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  align-items: center;

  > h2 {
    color: $spaceBlue;
	align-items: center;
  }

  > p {
    font-size: 24px;
    font-weight: 600;
	color: $golden;
  }

  &_loader {
    padding: 70px 0;
  }

  &_slotsBlock {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;

    &_slotsInfoWrapper {
      display: flex;
      flex-direction: row;
      column-gap: 5px;
      > h2 {
        text-align: left;
        color: $spaceBlue;
      }
    }
    &_slotsSection {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;
    }
  }
  &_emptySlotsBlock {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;

    &_emptySlotsInfoWrapper {
      display: flex;
      flex-direction: row;
      column-gap: 5px;
      > h2 {
        color: $spaceBlue;
      }
    }
    &_emptySlotsSection {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;
    }
  }

  &_nftsBlock {
    padding: 50px 0;
    &_tokenSection {
      display: flex;
      flex-direction: column;
      gap: 20px;
      &_tokenInfoWrapper {
        display: flex;
        flex-direction: row;
        column-gap: 5px;
        > h2 {
          color: $spaceBlue;
        }
      }
      &_tokenNfts {
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
      }
    }
  }

  &_seeMoreWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
}

@media (max-width: $small-screen) {
  .container {
    justify-content: center;
    > p {
      font-size: 20px;
    }

    &_nftsBlock,
    &_slotsBlock,
    &_emptySlotsBlock {
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      &_slotsInfoWrapper {
        > h2 {
          text-align: center;
        }
      }
      &_tokenSection {
        &_tokenInfoWrapper {
          justify-content: center;
          text-align: center;
          align-items: center;
        }
      }
      &_slotsSection,
      &_emptySlotsSection &_tokenSection {
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media (max-width: $mobile-screen) {
  .container {
    > p {
      font-size: 12px;
    }
    &_slotsBlock {
      &_slotsInfoWrapper {
        flex-direction: column;
        > h2 {
          font-size: 20px;
        }
      }
    }
    &_emptySlotsBlock {
      &_emptySlotsInfoWrapper {
        flex-direction: column;
        > h2 {
          font-size: 20px;
        }
      }
    }
    &_nftsBlock {
      &_tokenSection {
        &_tokeInfoWrapper {
          flex-direction: column;
          > h2 {
            font-size: 20px;
          }
        }
      }
    }
  }
}
