@import "../../assets/styles/variables.scss";

.button {
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  padding: 5px 10px;
  outline: none;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &_title_opacity {
    position: absolute;
    opacity: 0.3;
  }
}

// Colors
.btn_white {
  border: 1px solid $darkBlue;
  color: $darkBlue;
  background: $white;

  &:hover,
  &:focus {
    border: 1px solid $white;
    background-color: $lightBlue;
    color: $golden;
  }

  &:active {
    background-color: $lightBlue;
    color: $golden;
  }
}

.btn_olive {
  border: 1px solid $white;
  color: $emerald;
  background: $olive;

  &:hover,
  &:focus {
    background-color: $emerald;
    outline: none;
    color: $golden;
  }

  &:active {
    background-color: $emerald;
    color: $golden;
  }
}
.btn_blue {
  border: 1px solid $white;
  color: $spaceLightBlue;
  background: $darkBlue;

  &:hover,
  &:focus {
    background-color: $spaceLightBlue;
    outline: none;
    color: $golden;
  }

  &:active {
    background-color: $spaceLightBlue;
    color: $golden;
  }
}

// Sizes
.btn_medium {
  width: 80px;
}
.btn_large {
  width: 150px;
}

.btn_fit {
  width: fit-content;
}

@media (max-width: $mobile-screen) {
  .button {
    font-size: 12px;
  }
}
