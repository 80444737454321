@import "../../../assets/styles/variables.scss";

.container {
  width: 100%;
  height: 100%;
  z-index: 1001;
  position: fixed;
  top: 0;
  background: $blackBackground;
  display: flex;
  align-items: center;
  justify-content: center;

  &_modal {
    min-width: 370px;
    background: $darkBlue;
    border-radius: 15px;

    &_modalHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $golden;
      padding: 25px;
      column-gap: 25px;
      border-bottom: 1px solid $white;

      &_close {
        width: 20px;
        cursor: pointer;
      }
    }

    &_modalBody {
      padding: 25px;
      display: flex;
      flex-direction: column;
      row-gap: 15px;
    }

    &_modalFooter {
      display: flex;
      justify-content: flex-end;
      column-gap: 10px;
      padding: 15px 25px;
      border-top: 1px solid $white;
    }
  }
}

@media (max-width: $small-screen) {
  .modalHeader {
    > h3 {
      text-align: left;
      font-size: 20px;
    }
  }
}

@media (max-width: $mobile-screen) {
  .container {
    &_modal {
      min-width: 0;
      width: 100%;
      margin: 20px;
    }
  }
}
