@import "../../assets/styles/variables.scss";
@import "../../assets/styles/global.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;

  &_market {
    color: $spaceBlue;
    font-size: 32px;
    margin-bottom: 20px;
	
    &__YsSM {
      font-weight: 500;
      color: #0078d4;
      width: 29%;
      height: 195px;
	  @media (max-width: $mobile-screen) {
        width: 100%; /* Set width to 100% for mobile devices */
        height: 30vh; /* Set height to 30% of the viewport height for mobile devices */
      }  
    }

    // Add a separate class for general styles within .container_leaderboard
    &__general {
      // Define any styles you want to apply to other elements with .container_leaderboard
    }	
  }

  > h2 {
    color: #fffb00;
    font-size: 32px;
    margin-bottom: 20px;
  }

  > p {
    color: #fffb00;
    font-size: 20px;
  }

  > ul > li,
  ol > li {
    font-size: 18px;
  }

  @media (max-width: $mobile-screen) {
    > h2 {
      font-size: 24px;
    }

    > ul > li,
    ol > li,
    > p {
      font-size: 16px;
    }
  }
}

/* Modal styles */
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 600px;
  width: 100%;
  text-align: center;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

/* Table styles */
table {
  width: 60%;
  border-collapse: collapse;
  margin-top: 20px;

  th, td {
    border: 1px solid $spaceBlue;
    padding: 10px;
    text-align: left;
    color: #fffb00;

    &:first-child {
      text-align: center;
    }

    img {
      max-width: 100px;
      height: auto;
    }
  }
}
