@import "../../../assets/styles/variables.scss";
@import "../../../assets/styles/global.scss";

.dropdownMenu {
  position: absolute;
  right: 20px;
  top: 350px;
  background: $darkBlue;
  border: 1px solid $spaceBlue;
  border-radius: 15px;
  padding: 15px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  min-width: 180px;
  cursor: default;
  > a {
    font-size: 18px;
    display: flex;
    text-align: center;
    text-decoration: none;
    color: $golden;
    column-gap: 20px;
    cursor: pointer;
    @extend .orangeUnderlineHover;
  }

  &_line {
    margin: 10px 0;
    width: 100%;
    height: 1px;
    background: $spaceBlue;
  }

  &_walletInfo {
    width: fit-content;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    &_btnItem {
      font-size: 18px;
      text-align: center;
      text-decoration: none;
      color: $golden;
      cursor: pointer;
      @extend .orangeUnderlineHover;
    }
  }
}

@media (max-width: $mobile-screen) {
  .dropdownMenu {
    position: fixed;
    top: 350px;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 99vw;
    align-items: center;
  }
}
@media (max-width: 375px) and (min-width: 360px) {
  .dropdownMenu {
    position: fixed;
	top: 350px;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 99vw;
    align-items: center;
  }
}
@media (max-width: 314px) and (min-width: 276px) {
  .dropdownMenu {
    position: fixed;
    top: 350px;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 99vw;
    align-items: center;
  }
}