@import "../../assets/styles/variables.scss";
@import "../../assets/styles/global.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  align-items: center;

  &_leaderboard {
    color: $spaceBlue;

    &__YsSM {
      font-weight: 500;
      color: #0078d4;
      width: 29%;
      height: 195px;
	  @media (max-width: $mobile-screen) {
        width: 100%; /* Set width to 100% for mobile devices */
        height: 30vh; /* Set height to 30% of the viewport height for mobile devices */
      }  
    }

    // Add a separate class for general styles within .container_leaderboard
    &__general {
      color: $golden;
    }
  }

  &_playersTable {
    width: 60%; /* Imposta la larghezza della tabella al 60% della dimensione della pagina */
    margin: 0 auto; /* Centra la tabella nella pagina */

    text-align: center;
    border-collapse: collapse;

    > thead {
      > tr {
        > th {
          color: $spaceLightBlue;
          padding: 15px;
          font-size: 24px;
        }
      }
    }

    &_currentTr {
      border-radius: 8px;
      background-color: $blackBackground;
      transform: scale(1.1);
    }

    > tbody {
      > tr {
        transition: 0.2s ease-in-out;

        > td {
          color: $golden;
          padding: 15px;
          vertical-align: bottom;
          font-size: 24px;
        }
      }

      > tr:hover {
        border-radius: 8px;
        background-color: $blackBackground;
        transform: scale(1.1);
        
        > td {
          border: none;
          text-shadow: 0.2px 0.2px 20px $white;
        }
      }
    }

    > thead > tr > th {
      /* Imposta le larghezze delle colonne nella testa della tabella */
      &:nth-child(1) { width: 15%; } /* Pos */
      &:nth-child(2) { width: 32%; } /* Wallet */
      &:nth-child(3) { width: 35%; } /* Seasonal Upgrade-Points */
      &:nth-child(4) { width: 18%; } /* Token Bonus */
    }

    > tbody > tr > td {
      /* Imposta le larghezze delle colonne nel corpo della tabella */
      &:nth-child(1) { width: 15%; } /* Pos */
      &:nth-child(2) { width: 32%; } /* Wallet */
      &:nth-child(3) { width: 35%; } /* Seasonal Upgrade-Points */
      &:nth-child(4) { width: 18%; } /* Token Bonus */
    }
  }
}

@media (max-width: $mobile-screen), (max-width: $small-screen) {
  .container_playersTable > thead > tr > th,
  .container_playersTable > tbody > tr > td {
    font-size: 3vw;
  }
}
