@import "../../assets/styles/variables.scss";
@import "../../assets/styles/global.scss";

.container {
  width: 100%;
  color: $golden;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  &_mining {
    text-align: center;
    color: $spaceBlue;
  }
  &_mainInfo {
    display: flex;
    justify-content: center;
    gap: 20px;
    &_mines,
    &_limits,
    &_earnings {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
      > h2 {
        color: $spaceBlue;
        white-space: nowrap;
      }
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        > p {
          font-size: 20px;
          text-align: center;
        }
      }
    }
  }
  &_btnWrapper {
    display: flex;
    gap: 20px;
  }
  &_miningWrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media (max-width: $small-screen) {
  .container {
    &_mainInfo {
      &_mines,
      &_limits,
      &_earnings {
        text-align: center;
        > h2 {
          font-size: 4vw;
        }
        > div {
          > p {
            font-size: 2.4vw;
          }
        }
      }
    }
  }
}

@media (max-width: $mobile-screen) {
  .container {
    > h2 {
      font-size: 6vw;
    }
    &_mainInfo {
      gap: 10px;
      &_mines,
      &_limits,
      &_earnings {
        > h2 {
          font-size: 6vw;
        }
        > div {
          > p {
            font-size: 2.3vw;
          }
        }
      }
    }
  }
}
