@import "../../assets/styles/variables.scss";
@import "../../assets/styles/global.scss";

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-image: url("../../assets/images/backgrounds/welcome_section_background.jpg");
  &_errorSection {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $golden;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    > h1 {
      font-size: 150px;
    }
    > p {
      font-size: 30px;
    }
    > h2 {
      font-size: 50px;
      > a {
        color: $chartreuse;
        @extend .orangeUnderlineHover;
      }
    }
  }
}

@media (max-width: $small-screen) {
  .container {
    &_errorSection {
      > h1 {
        font-size: 19vw;
      }
      > p {
        font-size: 5vw;
      }
      > h2 {
        font-size: 5vw;
      }
    }
  }
}
