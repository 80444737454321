@import "../../assets/styles/variables.scss";
@import "../../assets/styles/global.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  color: $golden;
  > h2 {
    color: $spaceBlue;
    margin-bottom: 20px;
  }
  > h3 {
    color: $spaceBlue;
    margin-bottom: 20px;
  }
  > p {
    color: $spaceLightBlue;
    font-size: 20px;
  }
  > ul > li {
    font-size: 18px;
  }
  ol > li {
    font-size: 18px;
  }

  @media (max-width: $mobile-screen) {
    > h2 {
      font-size: 24px;
    }
	> h3 {
      font-size: 24px;
    }
    > ul > li,
    ol > li,
    > p {
      font-size: 16px;
    }
  }
}