@import "../../assets/styles/variables.scss";

.container {
  height: 60px;
  background-color: $darkBlue;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 1;

  &_navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: 100%;

    &_logoDiv {
      display: flex;
      align-items: center;

      > img {
        cursor: pointer;
        width: 70px;
      }

      &_walletBalance {
        color: $spaceLightBlue;
        margin-left: 15px;
      }
    }
    &_dropDown {
      position: relative;
      cursor: pointer;
      display: block;

      &_openHamburger {
        width: 25px;
        height: 25px;
      }
      &_closeHamburger {
        width: 25px;
        height: 25px;
      }
    }
  }

  @media (max-width: $small-screen) {
    .container {
      &_navbar {
        &_logoDiv {
          > img {
            width: 50px;
          }
          &_walletBalance {
            font-size: 12px;
          }
        }
      }
    }
  }

  @media (max-width: $mobile-screen) {
    .container {
      &_navbar {
        &_logoDiv {
          &_walletBalance {
            font-size: 2.5vw;
          }
        }
      }
    }
  }
}
