@import "../../assets/styles/variables.scss";
@import "../../assets/styles/global.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  color: $golden;
  &_gameRules,
  &_gameInstructions {
    > h2 {
      color: $spaceBlue;
      margin-bottom: 20px;
    }
    > p {
      color: $spaceLightBlue;
      font-size: 20px;
    }
    > ul > li {
      font-size: 18px;
    }
    ol > li {
      font-size: 18px;
    }
  }
}

@media (max-width: $mobile-screen) {
  .container {
    &_gameRules,
    &_gameInstructions {
      > h2 {
        font-size: 24px;
      }
      > ul {
        > li {
          font-size: 16px;
        }
      }
      > ol {
        > li {
          font-size: 16px;
        }
      }
      > p {
        font-size: 16px;
      }
    }
  }
}
