@import "../../assets/styles/variables.scss";
@import "../../assets/styles/global.scss";
footer {
  margin-top: auto;
}
.container {
  width: 100%;
  background-color: $darkBlue;
  color: $golden;
  padding: 20px 0;
  text-align: center;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
  > p {
    > a {
      color: $chartreuse;
      @extend .blueUnderlineHover;
    }
    > span {
      cursor: pointer;
      color: $spaceBlue;
      @extend .blueUnderlineHover;
    }
  }

  > div {
    display: flex;
    justify-content: center;
    column-gap: 10px;
    font-size: 16px;
    > a {
      > img {
        width: 48px;
        height: 48px;
      }
      :hover {
        transition: all 0.2s ease-in-out;
        transform: scale(1.1);
      }
      :not(:hover) {
        transition: all 0.2s ease-in-out;
        transform: scale(1);
      }
    }
  }
}

@media (max-width: $mobile-screen) {
  .container {
    font-size: 16px;
  }
}
