@import "../../assets/styles/variables.scss";
@import "../../assets/styles/global.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 50px;
  align-items: center;
  text-align: center;
  &_welcomeSection {
    display: flex;
    flex-direction: column;
    > h1 {
      text-align: center;
      color: $spaceBlue;
      margin: 20px;
    }
    > p {
      color: $golden;
      font-size: 24px;
    }
  }
    &__YsSM {
      font-weight: 500;
      color: #0078d4;
      width: 29%;
      height: 195px;
	  @media (max-width: $mobile-screen) {
        width: 100%; /* Set width to 100% for mobile devices */
        height: 30vh; /* Set height to 30% of the viewport height for mobile devices */
      }  
    }  
}

@media (max-width: $small-screen) {
  .container {
    &_welcomeSection {
      > p {
        font-size: 15px;
      }
    }
  }
}
@media (max-width: $mobile-screen) {
  .container {
    &_welcomeSection {
      > h1 {
        font-size: 6vw;
      }
      > p {
        font-size: 3vw;
      }
    }
  }
}
