$error: #d70000;

$chartreuse: #b0fc38;
$olive: #98bf64;
$emerald: #028a0f;
$golden: #fffb00;

$white: #fffdfa;

$darkBlue: #000000d6;
$lightBlue: #474a4e;
$spaceBlue: #0078d4;
$spaceLightBlue: #00b7ff;

$blackBackground: #0700228e;
$blueBorder: #0078d4cc;

$default-screen: 1280px;
$small-screen: 768px;
$mobile-screen: 480px;
